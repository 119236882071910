.sent-message {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.received-message {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.sent-message .ant-list-item-meta {
  text-align: right;
  max-width: 50%;
}

.received-message .ant-list-item-meta {
  text-align: left;
  max-width: 50%;
}
.scrollable-container {
  height: 50vh;
  overflow-y: auto;
  padding-right: 10px;
}

.scrollable-container::-webkit-scrollbar {
  width: 8px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
