@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
body {
  font-family: "Inter", sans-serif !important;
}

.fixed-layout-wrapper {
  padding-top: 0px;
}

.fixed-content-ml {
  margin-left: 0px;
  margin-top: 100px;
}

.custom-container {
  width: 100%;
  max-width: 1199px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.custom-input {
  height: 44px;
  background: #ffffff !important;
  border: 0.5px solid #455a64 !important;
  border-radius: 4px !important;
}
.custom-input::placeholder {
  font-size: 14px !important;
  color: #455a64 !important;
}

textarea {
  border: 0.5px solid #455a64 !important;
  border-radius: 4px !important;
}

textarea::placeholder {
  font-size: 14px !important;
  color: #455a64 !important;
}

.custom-select .ant-select-selector {
  height: 41px !important;
  border: 0.5px solid #455a64 !important;
  border-radius: 4px !important;
}
.custom-select .ant-select-selection-placeholder {
  font-size: 14px !important;
  color: #455a64 !important;
}

.custom-select .ant-select-arrow .anticon-down svg {
  fill: #000 !important;
}

.ant-form-item-label {
  padding-bottom: 0px !important;
}

.custom-btn-bg {
  background: #f7a325 !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  border: none !important;
  height: 45px !important;
}

.custom-label {
  color: #263238 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.section-bg-white {
  background-color: #fff !important;
}

.h-41 {
  height: 41px !important;
}

.bread-crumb-spacer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-bread-crumb-box h2 {
  font-weight: 600;
  font-size: 24px;
  color: #263238;
  text-transform: capitalize;
}

.custom-bread-crumb-box .ant-breadcrumb > span:last-child {
  font-size: 14px;
  color: #f7a325;
  cursor: text;
}

.custom-bread-crumb-box .ant-breadcrumb > span {
  cursor: pointer;
  color: #666666;
}

.content-wrapper {
  min-height: 292px;
  background: rgba(245, 96, 56, 0.05);
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.top-bottom-padding {
  padding-top: 80px;
  padding-bottom: 80px;
}

.custom-select-box .ant-picker {
  border: 0.5px solid #455a64;
  border-radius: 4px;
  height: 41px;
}
.custom-date-picker {
  background: #ffffff;
  border: 0.5px solid #455a64 !important;
  border-radius: 4px !important;
  height: 41px;
}
.custom-date-picker input::placeholder {
  color: #455a64 !important;
}

.custom-select-box .ant-select-selector {
  border: 0.5px solid #455a64 !important;
  border-radius: 4px !important;
  height: 41px !important;
}

.custom-select-box .anticon svg {
  fill: #455a64;
}

.custom-select-box .ant-select-selector .ant-select-selection-item {
  line-height: 38px !important;
}

.custom-btn-primary {
  background: #f7a325 !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  height: 41px !important;
  color: #ffff !important;
  text-transform: capitalize;
}

.custom-btn-secondary {
  color: #f7a325 !important;
  background: transparent !important;
  height: 41px !important;
  text-transform: capitalize;
  border: 1px solid #f7a325 !important;
  border-radius: 4px !important;
  height: 41px !important;
}

.custom-btn-danger {
  background: #f56038 !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  height: 41px !important;
  color: #ffff !important;
  text-transform: capitalize;
}

.custom-btn-sucess {
  background: #12492f !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  height: 41px !important;
  color: #ffff !important;
  text-transform: capitalize;
}

.custom-btn-default {
  color: #f7a325 !important;
  background: transparent !important;
  height: 41px !important;
  text-transform: capitalize;
  border: 1px solid #f7a325 !important;
  border-radius: 4px !important;
  height: 41px !important;
}

.custom-btn-default-save {
  color: #fff !important;
  background: #a8a8a8 !important;
  height: 41px !important;
  text-transform: capitalize;
  border-radius: 4px !important;
  height: 41px !important;
}
.custom-btn-download {
  background: #ffffff !important;
  border: 0.5px solid #455a64 !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 41px !important;
}
.custom-btn-download-chalan {
  background: #12492f;
  border: 0.5px solid #455a64 !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 41px !important;
}

.custom-btn-upload {
  background: #ffffff !important;
  border: 0.5px solid #455a64 !important;
  border-radius: 4px !important;
  height: 41px !important;
  text-decoration: underline;
}

.custom-btn-download-all {
  height: 41px !important;
  background: #f7a325 !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  color: #fff !important;
}

.custom-btn-download-chalan {
  height: 41px !important;
  background: #12492f !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  color: #fff !important;
  text-transform: capitalize;
}
.down-btn-text::after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 1px;
  height: 44%;
  bottom: 11px;
  right: 36px;
}
.download-btn-all-text::after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 1px;
  height: 52%;
  bottom: 9px;
  right: 59px;
}

.go-back {
  color: #455a64;
  cursor: pointer;
  font-size: 12px;
  display: block;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.custom-table-pagination .ant-pagination-prev {
  display: none;
}

.custom-table-pagination .ant-pagination-item {
  font-weight: 550;
  min-width: 32px !important;
  height: 32px !important;
  line-height: 29px !important;
  border-radius: 50% !important;
  margin-right: 15px !important;
  background-color: #e5e5e5 !important;
}

.custom-table-pagination .ant-pagination-item-active,
.ant-pagination-next {
  min-width: 32px !important;
  color: #fff;
  font-weight: 550;
  height: 32px !important;
  margin: 0;
  line-height: 29px !important;
  border-radius: 50% !important;
  background-color: #f7a325 !important;
  border: none !important;
}

.custom-table-pagination .ant-pagination-item-active,
.ant-pagination-next svg {
  fill: #fff;
}

.custom-table-pagination .ant-pagination-item-active,
.ant-pagination-next button {
  line-height: 0;
}

.table-pagination-wrapper {
  position: relative;
}
.table-pagination-result {
  font-size: 14px;
  color: #455a64;
  position: absolute;
  right: 33px;
  bottom: 24px;
}

/*=============================
    SITE-HEADER
==============================*/

.site-header-wrapper {
  background-color: #fff;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 40px;
  padding-left: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 9;
}
.mobile-header-wrapper {
  display: none;
}

.site-header-wrapper > img {
  cursor: pointer;
}

.notifications {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.portal-heading {
  font-size: 20px;
  font-weight: 600;
}

.notifications img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

/*=============================
    SIDE-BAR
==============================*/

.side-bar-nav {
  position: fixed !important;
  height: 100% !important;
  background-color: transparent !important;
  width: 139px !important;
  max-width: 93px !important;
  min-width: 126px !important;
  border-right: 1px solid #eeeeee !important;
  z-index: 1;
}

.side-bar-nav:after {
  position: absolute !important;
  content: "" !important;
  width: 4px !important;
  background-color: yellow !important;
  right: 0px !important;
}

.aside-bar {
  height: 100vh;
  /* background-color: transparent !important;  */
  padding-top: 10px !important;
}

.side-bar-nav .aside-bar .ant-menu-item::after {
  left: 0;
  right: unset;
  border-right: 4px solid #f56038;
  height: 70px;
}

.side-bar-nav .ant-menu-inline {
  border: none;
}

.aside-bar .ant-menu-item-selected {
  background-color: transparent !important;
}

.aside-bar .ant-menu-item {
  font-size: 13px;
  font-weight: 500;
  padding-left: 12px !important;
  color: #263238 !important;
  text-transform: capitalize;
  margin-bottom: 10px !important;
}

.aside-bar .ant-menu-item a {
  text-decoration: none;
}

.aside-bar .ant-menu-title-content a {
  padding: 0px !important;
}

.aside-bar .ant-menu-item-selected a {
  color: #f56038 !important;
}

.aside-bar .ant-menu-item a:hover {
  color: #f56038;
}

.aside-bar .ant-menu-item {
  height: 67px !important;
}

.aside-bar .ant-menu-item-selected svg {
  fill: purple !important;
}

.collapse-users {
  position: relative !important;
}

.collapse-user-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}

.collapseable-user-dropdown {
  position: absolute !important;
  right: -95px;
  z-index: 9999 !important;
  background: #f56038;
  width: 104px;
  height: 70px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px;
  text-align: center;
  list-style: none;
  border-radius: 4px;
}

.collapseable-user-dropdown li {
  cursor: pointer !important;
  border-bottom: 0.5px solid #fff;
}

.collapseable-user-dropdown li:last-child {
  border-bottom: none;
}
.position-1 {
  top: 381px;
}
.position-2 {
  top: 458px;
}
.position-3 {
  top: 534px;
}
/*=============================
    CARDS
==============================*/
.service-card-body {
  background-color: #ffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 15px;
}

.service-title {
  display: block;
  font-size: 14px;
  font-weight: 650;
  margin-bottom: 10px;
  text-align: center;
  text-transform: capitalize;
}

.service-counter-body {
  background: rgba(104, 84, 255, 0.2);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.services-status-count {
  color: #6854ff;
  font-weight: 600;
}

.service-heading h2 {
  font-weight: 600;
  font-size: 24px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.service-type-card {
  height: 232px;
}
.issue-type-card {
  min-height: 313px;
}

.service-description {
  color: #455a64;
  font-size: 14px;
  text-transform: capitalize;
}

.service-description-counter {
  color: #263238;
  font-size: 14px;
  font-weight: 500;
}

.issue-title {
  color: #455a64;
  font-weight: 700;
  font-size: 14px;
  width: 162px;
  height: 30px;
  text-align: left;
  line-height: 18px;
  text-transform: capitalize;
}

.prog-bar {
  width: 25%;
  height: 4px;
  border-radius: 12px;
}

.bar-rejected {
  background-color: #ff3939;
}

.bar-progress {
  background-color: #9847c6;
}

.bar-completed {
  background-color: #91ea9d;
}

.bar-tat {
  background-color: #ff8139;
}

/*=============================
    REPORTS-SCREEN
==============================*/
.lg-w-75 {
  width: 75%;
}

.report-filter-label {
  color: #263238;
  font-size: 14px;
  text-transform: capitalize;
}

.show-filters {
  border: 1px solid #0e552d !important;
  border-radius: 4px !important;
  height: 41px !important;
  color: #0e552d;
  text-transform: capitalize;
  font-weight: 500;
}

.report-management-table {
  margin-bottom: 20px;
}

.report-management-table .ant-table-thead > tr > th {
  background-color: #f7a325 !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.report-management-table .ant-table-thead > tr > th:before {
  display: none !important;
}

.report-management-table .ant-table-row {
  line-height: 2;
}

.filter-row-border {
  border-bottom: 1px solid black;
}

.add-filter-box {
  background: #f4f4f4;
  min-height: 285px;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.add-filter-tags {
  box-sizing: border-box;
  background: #fff;
  border: 0.5px solid #455a64;
  border-radius: 4px;
  color: #000000d9;
  font-size: 14px;
  height: 41px;
  padding: 0 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.remove-filter-tags {
  box-sizing: border-box;
  background: rgba(247, 163, 37, 0.2);
  border: 0.5px solid #455a64;
  border-radius: 4px;
  color: #000000d9;
  font-size: 14px;
  height: 41px;
  padding: 0 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.closeabel-row {
  position: relative;
}

.close-filter-icon {
  position: absolute;
  right: 20px;
}

.custom-select-column-box .ant-select-selector {
  border: 0.5px solid #455a64 !important;
  border-radius: 4px !important;
  min-height: 41px !important;
}

.custom-select-column-box .ant-select-selection-placeholder {
  color: #333 !important;
}
.custom-select-column-box .ant-select-multiple .ant-select-selection-item {
  font-weight: bold;
  color: #fff !important;
  background-color: orange !important;
}
.report-modal-heading {
  color: #263238;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
}

.report-modal-label {
  font-weight: 400;
  font-size: 14px;
  color: #263238;
}

.report-show-hide-col-modal .ant-modal-content {
  padding-top: 30px;
  position: relative;
  border-radius: 4px;
}

.report-show-hide-col-modal .ant-modal-body {
  padding: 0px;
  min-height: 270px;
}

.modal-close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.report-show-hide-col-modal .ant-modal-header {
  border-bottom: none;
}

.report-show-hide-col-modal .ant-modal-header .ant-modal-title {
  text-align: center;
  font-size: 24px;
}

.report-show-hide-col-modal .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-top: none;
  padding-bottom: 30px;
}

.report-modal-cancel-btn {
  color: #f7a325 !important;
  height: 41px !important;
  width: 120px;
  background: transparent !important;
  border-radius: 4px !important;
  border: 1px solid #f7a325 !important;
}
.report-modal-save-btn {
  height: 41px !important;
  width: 120px;
  background: #f7a325 !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  border: none !important;
}

/*===============================
    ONE-WINDOW-OPERATION-SCREEN
================================*/

.window-wrapper {
  min-height: 517px;
  background: rgba(245, 96, 56, 0.05);
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}
.services-heading {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin-bottom: 15px;
}
.gender-label {
  font-size: 14px;
  margin-bottom: 7px;
  padding-top: 2px;
}
.gender-box {
  height: 44px;
  background: #ffffff !important;
  border: 0.5px solid #455a64 !important;
  border-radius: 4px !important;
}

.gender-box .ant-radio-group {
  padding-top: 9px !important;
  padding-left: 15px !important;
}
.gender-box .ant-radio-wrapper:first-child {
  margin-right: 60px;
}
.applicant-heading {
  font-weight: 600;
  font-size: 16px;
  color: #263238;
  text-transform: capitalize;
}
.attach-doc-heading {
  font-size: 14px;
  color: #455a64;
  text-transform: capitalize;
}
.spacer-5 {
  margin-bottom: 50px;
}
.spacer-3 {
  margin-bottom: 34px;
}

/*===============================
    ASSIGNMENTS-SCREEN
================================*/
.id-search-box {
  position: relative;
}
.id-search-icon {
  position: absolute;
  right: 12px;
  top: 8px;
  z-index: 9999;
}
.id-search-box input {
  height: 41px;
  background: #eeeeee !important;
  border-radius: 4px !important;
  border: none !important;
}
.id-search-box input::placeholder {
  color: #455a64 !important;
}
.id-search-box .ant-input-group-addon {
  display: none;
  background-color: #eeeeee !important;
}

/*===============================
    ASSIGNMENTS-DETAILS-SCREEN
================================*/
.user-info-wrapper {
  min-height: 370px;
  background: rgba(245, 96, 56, 0.2);
  padding-left: 30px;
  padding-right: 15px;
  padding-top: 30px;
}
.user-detail-wrapper {
  min-height: 370px;
  background: rgba(245, 96, 56, 0.05);
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 30px;
}
.app-heading {
  font-size: 14px;
  font-weight: 600;
  color: #263238;
  text-transform: capitalize;
  margin-bottom: 2px;
}
.app-detail {
  font-size: 14px;
  font-weight: 400;
  color: #455a64;
  display: block;
  line-height: 19px;
}

.comments-wrapper,
.attachments-wrapper {
  min-height: 317px;
  background: rgba(245, 96, 56, 0.05);
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
.latest-comment-body {
  background: #ffffff;
  border: 0.5px solid #455a64;
  border-radius: 4px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 8px;
}

.latest-comment-heading {
  color: #455a64;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.latest-comment-info {
  color: #455a64;
  font-size: 13px;
}

.recent-file-name::after {
  content: "";
  position: absolute;
  background-color: #c4c4c4;
  width: 1px;
  height: 52%;
  bottom: 9px;
  right: 35px;
}

.rec-atc-btn {
  background: #ffffff !important;
  border: 0.5px solid #455a64 !important;
  border-radius: 4px !important;
  height: 41px !important;
  text-decoration: underline;
}

.comment-date-time {
  color: #828282;
  font-size: 10px;
}

.reject-app-modal .ant-modal-content {
  padding-top: 30px;
  position: relative;
  border-radius: 4px;
}

.reject-app-modal .ant-modal-body {
  padding: 0px;
  min-height: 270px;
}

.reject-app-modal .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
  border-top: none;
  padding-bottom: 30px;
  padding-right: 50px;
}

.reject-app-modal-cancel-btn {
  color: #f7a325 !important;
  height: 41px !important;
  width: 120px;
  background: transparent !important;
  border-radius: 4px !important;
  border: 1px solid #f7a325 !important;
}

.reject-app-modal-reject-btn {
  height: 41px !important;
  width: 120px;
  background: #f7a325 !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  border: none !important;
}

/*===============================
    MANAGE-USERS-SCREEN
================================*/

/*===============================
    MANAGE-ROLES-SCREEN
================================*/
.assigned-rights-box {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 15px;
  width: 100%;
  min-height: 140px;
  padding: 15px 10px;
  font-size: 14px;
  line-height: 1.6;
  border: 0.5px solid #455a64;
  border-radius: 4px;
  overflow: hidden;
  word-wrap: break-word;
}
.assigned-rights-label {
  font-size: 14px;
  color: #263238;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.assign-tag {
  height: 36px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 8px;
  padding: 1px 10px 1px 8px;
  font-size: inherit;
  font-weight: 400;
  text-align: center;
  color: #455a64;
  background: #eeeeee;
  border: 0.5px solid #455a64;
  border-radius: 4px;
  cursor: default;
}
.assign-tag-name {
  margin-right: 3px;
}
.confirmation-main-heading {
  font-weight: 600;
  font-size: 24px;
  color: #263238;
  text-align: center;
}
.confirmation-sub-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #263238;
}

.manage-role-modal .ant-modal-content {
  padding-top: 30px;
  position: relative;
  border-radius: 4px;
}

.manage-role-modal .ant-modal-body {
  padding: 0px;
  min-height: 235px;
}

.manage-role-modal .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-top: none;
  padding-bottom: 80px;
}

.manage-role-modal-no-btn {
  color: #f7a325 !important;
  height: 41px !important;
  width: 120px;
  background: transparent !important;
  border-radius: 4px !important;
  border: 1px solid #f7a325 !important;
}

.manage-role-modal-yes-btn {
  height: 41px !important;
  width: 120px;
  background: #f7a325 !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  border: none !important;
}
